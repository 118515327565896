.fhir-datatype__Telecom__item-label {
  margin-right: 10px;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  background-color: #f8f9fa;
  color: #606060;
}
