.fhir-resource__Patient__patientContact-relationship {
  font-weight: 700;
}

.fhir-resource__Patient__patient-avatar {
  border: 4px solid #fff;
  border-radius: 50%;
  margin-right: 10px;
}

.fhir-resource__Patient__patient-block {
  display: flex;
  align-items: baseline;
}

.fhir-resource__Patient__BirthDate-block {
  font-weight: 700;
  color: #6c757d;
}
