/* HumanName */
.fhir-datatype__HumanName__not-primary-block {
  font-weight: 700;
}

.fhir-datatype__HumanName__Header--primary {
  margin-bottom: 0;
  font-weight: 500;
  line-height: 1.2;
  font-size: 1.5rem;
}

.fhir-datatype__HumanName__use-block {
  margin-left: 3px;
}
